<template>
  <a-input size="large" class="ui_text_input" v-bind="{ ...$attrs, ...$props }" v-on="listeners" />
</template>

<script>
  import fixListeners from '@/utils/fixListeners';

  export default {
    name: 'UiTextInput',
    inheritAttrs: false,
    computed: {
      ...fixListeners()
    }
  };
</script>

<style lang="scss">
  .ui_text_input {
    height: 48px;
  }
</style>
